import html from './index.html';
import styles from './index.scss';

/**
 * Renders Side By Side banner text + image
 */
class BannerSideBySide extends HTMLElement {
  public shadowRoot!: ShadowRoot;

  readonly dataset!: {

    /**
     * Drive CSS for aligning image left or right on page
     */
    imageAlignment: string;

    /**
     * Boolean represented as string to indicate if no image is provided
     */
    noImage: string;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'banner-side-by-side': BannerSideBySide;
  }
}

// This module is potentially loaded more than once.

if (!customElements.get('banner-side-by-side')) {
  customElements.define('banner-side-by-side', BannerSideBySide);
}
